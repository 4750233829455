import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const Button = ({
  bgColor,
  textColor,
  iconColor,
  children,
  icon,
  animateIcon,
  onClick,
}) => {
  const [isHovered, setHovered] = React.useState(false)
  const handleHover = () => setHovered(!isHovered)
  const animate = !!animateIcon && isHovered

  return (
    <button
      className="flex justify-between items-center font-bold text-black-500 text-lg/[normal] -tracking-[0.0225rem]"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={onClick}
    >
      <div
        className={`flex h-[3rem] justify-between items-center text-left ${bgColor} md:gap-[8.834px] xl:gap-[12px] px-5 md:rounded-[1.472px] xl:rounded-sm hover:bg-opacity-90`}
      >
        <p className={textColor}>{children}</p>
        <motion.div
          className={`ml-4 ${iconColor}`}
          animate={{
            x: animate ? [0, 50, -10, 0, 0, 0] : 0,
            opacity: animate ? [1, 0, 0, 1, 1, 1] : 1,
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          {icon}
        </motion.div>
      </div>
    </button>
  )
}

Button.propTypes = {
  animateIcon: PropTypes.bool,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
}

export default Button
