import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import SEO from "components/seo"
import SubFooter from "components/sub-footer"
import Button from "components/button-2"

import { ArrowRightIcon } from "@primer/octicons-react"
import "mono-icons/iconfont/icons.css"

import GitHub_Review_Video from "images/GitHub_Reviews.mp4"
import GitHub_Review from "images/GitHub_Review.png"
import IDE_Review from "images/IDE_Review.png"

const SourceryReview = () => {
  return (
    <div>
      <SEO
        title="Sourcery Code Review"
        description="Get instant code reviews in GitHub or in your IDE"
        keywords={[
          `sourcery`,
          `code review`,
          `developer tools`,
          `github`,
          `ai`,
        ]}
      />

      {/* header start */}
      <div className="bg-black-500 py:20 lg:py-40 border-solid border-b border-[#000] overflow-y-hidden">
        <div className="flex items-center justify-center mx-auto overflow-x-hidden max-w-screen-2xl pt-6 overflow-y-hidden">
          <div className="w-full items-center mx-5 grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 lg:mr-0 gap-6 lg:gap-0 lg:px-16 overflow-y-hidden">
            <div className="flex flex-col justify-between items-start lg:items-start mt-24 col-span-1 space-y-7 lg:mt-0">
              <div className="px-6 py-4 rounded-full border-2 border-blue-200">
                <p className="text-blue-200 uppercase font-mono font-bold text-xs leading-[0.875rem]">
                  Beta
                </p>
              </div>
              <h1 className="text-3xl text-left text-white 2xl:text-6xl md:text-5xl lg:max-w-lg ">
                Code Review in seconds, not days
              </h1>
              <p className="text-base lg:text-xl text-black-200 text-left lg:max-w-md">
                Get peer-review-style feedback on every one of your code
                changes, automatically. It&apos;s free to try out.
              </p>
              <div className="flex gap-4 lg:gap-6 flex-col sm:flex-row">
                <Link to="https://github.com/apps/sourcery-ai/installations/new">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-blue-200"
                    textColor="text-black-500"
                    iconColor="text-black-500"
                    animateIcon
                  >
                    Get Started in GitHub
                  </Button>
                </Link>

                <OutboundLink href="https://calendly.com/tim-gilboy/30min">
                  <Button
                    icon={<i className="mi-calendar font-bold" />}
                    bgColor="bg-black-200"
                    textColor="text-black-500"
                    iconColor="text-black-500"
                  >
                    Schedule Demo
                  </Button>
                </OutboundLink>
              </div>
            </div>
            <div className="mx-auto w-full col-span-1 max-w-xl pb-8 bg-hero-team h-full min-h-[400px]">
              <div className="flex flex-col justify-center items-center">
                {<video src={GitHub_Review_Video} controls autoPlay muted />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header end */}

      <div className="bg-black-500 lg:py-5">
        <div className="max-w-screen-2xl mx-auto relative z-10">
          <div className="bg-white py-16 space-y-9 lg:py-[8rem] lg:mx-[2.5rem] rounded-2xl relative">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col justify-center">
                <div className="flex mb-4">
                  <div className="mr-4">
                    <i className="mi-repeat text-pink-200 " />
                  </div>
                  <p className=" mt-1 ml-3 font-mono font-bold text-black-300 text-sm">
                    %timeit review speed
                  </p>
                </div>
                <div>
                  <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500 leading-none">
                    Get immediate reviews on every pull requests
                  </p>
                  <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6 mb-4 space-y-4">
                    <p>
                      Slow downs in code reviews leads to slow downs in
                      development.
                    </p>
                    <p>
                      Help your team continue to work at a high velocity by
                      getting faster feedback and being able to stay in flow
                      more easily.
                    </p>
                    <p>
                      Sourcery integrates into your code hosting platform to
                      give you an initial review in the same place you conduct
                      peer reviews, so your workflow stays the same.
                    </p>
                  </p>
                </div>

                <Link to="https://github.com/apps/sourcery-ai/installations/new">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-pink-100"
                    textColor="text-black-500"
                    iconColor="text-pink-500"
                    animateIcon
                  >
                    Get Code Reviews in GitHub
                  </Button>
                </Link>
              </div>
              <img src={GitHub_Review} className="z-10" />
            </div>

            {/* shift reviews left */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col justify-center md:order-2">
                <div className="flex mb-4">
                  <div className="mr-4">
                    <i className="mi-repeat text-pink-200 " />
                  </div>
                  <p className=" mt-1 ml-3 font-mono font-bold text-black-300 text-sm">
                    shift reviews left
                  </p>
                </div>
                <div>
                  <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500 leading-none">
                    Get reviews on demand while you write code
                  </p>
                  <p className="lg:max-w-[25rem] text-sm text-black-300 space-y-4 tracking-wide leading-6 mb-4">
                    <p>
                      Have you ever wanted to get feedback before you open a PR?
                    </p>
                    <p>
                      You can request Sourcery reviews in your IDE to get on
                      demand review feedback, before anyone else looks at your
                      changes.
                    </p>
                  </p>
                </div>
                <Link to="https://research.typeform.com/to/zXGEZAjD">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-pink-100 mt-4"
                    textColor="text-black-500"
                    iconColor="text-pink-500"
                    animateIcon
                  >
                    Request IDE Access
                  </Button>
                </Link>
              </div>
              <img src={IDE_Review} className="z-10" />
            </div>
            {/* end shift reviews left */}
          </div>
        </div>
      </div>

      <SubFooter />
    </div>
  )
}

export default SourceryReview
